import { default as indexPegSUqWu3KMeta } from "/tmp/build_75a0ea3f/pages/connections/index.vue?macro=true";
import { default as indexVhLu20Z5zPMeta } from "/tmp/build_75a0ea3f/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_934pzINCzxT4Meta } from "/tmp/build_75a0ea3f/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93BZ6b8rV4SEMeta } from "/tmp/build_75a0ea3f/pages/gallery/[file_id].vue?macro=true";
import { default as galleryRcq8Apsk0fMeta } from "/tmp/build_75a0ea3f/pages/gallery.vue?macro=true";
import { default as indexAfKypQsYC3Meta } from "/tmp/build_75a0ea3f/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93Ucexx1jzP0Meta } from "/tmp/build_75a0ea3f/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45in3IDAEg8WD4Meta } from "/tmp/build_75a0ea3f/pages/log-in.vue?macro=true";
import { default as log_45outucnSs7NtTJMeta } from "/tmp/build_75a0ea3f/pages/log-out.vue?macro=true";
import { default as indexRH9xs0rQPEMeta } from "/tmp/build_75a0ea3f/pages/notifications/index.vue?macro=true";
import { default as projectsABTJszrLnQMeta } from "/tmp/build_75a0ea3f/pages/projects.vue?macro=true";
import { default as _91file_id_93U2aRR3Rsw6Meta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93VZPTrNLWj3Meta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexnhb7QAOYipMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visited3PoO4uVMkEMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93sqyxG9C9UdMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93gBc30dvmeDMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93gCvGUXLz3nMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexNbsj4nQTmOMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93qNTeFWRGxlMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93TeHpT3dp82Meta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as index3d97tvqEGUMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93e9KDL92lU7Meta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectskiNzC4SgfJMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93ffXzY87n0AMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93IvPNStXk1BMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexSEHM7hkludMeta } from "/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomeeTHOVegpnUMeta } from "/tmp/build_75a0ea3f/pages/welcome.vue?macro=true";
import { default as component_45stubraTeWxXNTIMeta } from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubraTeWxXNTI } from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indexPegSUqWu3KMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexVhLu20Z5zPMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_75a0ea3f/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryRcq8Apsk0fMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_75a0ea3f/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexAfKypQsYC3Meta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_75a0ea3f/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_75a0ea3f/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_75a0ea3f/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexRH9xs0rQPEMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsABTJszrLnQMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexnhb7QAOYipMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visited3PoO4uVMkEMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: index3d97tvqEGUMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectskiNzC4SgfJMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexSEHM7hkludMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeeTHOVegpnUMeta || {},
    component: () => import("/tmp/build_75a0ea3f/pages/welcome.vue")
  },
  {
    name: component_45stubraTeWxXNTIMeta?.name,
    path: "/",
    component: component_45stubraTeWxXNTI
  },
  {
    name: component_45stubraTeWxXNTIMeta?.name,
    path: "/library",
    component: component_45stubraTeWxXNTI
  },
  {
    name: component_45stubraTeWxXNTIMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubraTeWxXNTI
  }
]