import revive_payload_client_4sVQNw7RlN from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/tmp/build_75a0ea3f/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_75a0ea3f/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_75a0ea3f/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/tmp/build_75a0ea3f/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_Jozdw60ZsE from "/tmp/build_75a0ea3f/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_tMGwffvjBc from "/tmp/build_75a0ea3f/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/tmp/build_75a0ea3f/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import slideovers_X7whXyrIWR from "/tmp/build_75a0ea3f/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/tmp/build_75a0ea3f/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/tmp/build_75a0ea3f/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/tmp/build_75a0ea3f/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/tmp/build_75a0ea3f/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_ANExhJHvcR from "/tmp/build_75a0ea3f/plugins/auth.js";
import error_handlers_client_tiRhdctTL6 from "/tmp/build_75a0ea3f/plugins/error-handlers.client.js";
import misc_client_x0s9k909lG from "/tmp/build_75a0ea3f/plugins/misc.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_Jozdw60ZsE,
  plugin_tMGwffvjBc,
  plugin_ghbUAjaD3n,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  auth_ANExhJHvcR,
  error_handlers_client_tiRhdctTL6,
  misc_client_x0s9k909lG
]